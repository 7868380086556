export interface SlotMap {
	id?: 					number ;
	fkPageProfile?: 	number,
	slotId:				number
}

export interface PageProfile {
	id?:                    number;
	siteId:                 number;
	name:                   string;
	layoutId:               number,
	hasStructure:           boolean| null,
	contentId:              number,
	structureId:            number | null,
	structureTemplateId:    number | null,
	structureContentId:     number | null,
	hasForm:                boolean,
	hasProduct:             boolean,	
	hasCta:                 boolean,
	enabled:                boolean,
	slotMaps:               Array<SlotMap>;
}


export function PageProfileFactory(fkSite: number): PageProfile {
	return {
		siteId:              fkSite,
		name:                '',
		layoutId:            0,
		hasStructure:        false,
		contentId:           0,
		structureId:         null,
		structureTemplateId: null,
		structureContentId:  null,
		hasForm:             false,
		hasProduct:          false,	
		hasCta:              false,
		enabled:             true,
		slotMaps:            [],
	} as PageProfile;
}