import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import { SiteService, Site } from '@/services/SiteService';


@Module({
	namespaced: true
})
export default class SiteStore extends VuexModule {
	siteService: SiteService = new SiteService();
	sites: Site[] = [];
	chosenClientId = '';
	isNewClient = true;
	chosenSiteId = '';


	@Mutation
	setSites(sites: Site[]): void {
		this.sites = sites;
	}

	@Mutation
	updateClientId(clientId: string): void {
		this.chosenClientId = clientId;
	}

	@Mutation
	setSiteId(siteId: string): void {
		this.chosenSiteId = siteId;
	}
	
	@Action
	clearClientId(): void {
		this.context.commit('setSites', []);
		this.context.commit('setSiteId', '');
		this.context.commit('updateClientId', '');
	}

	@Action({ commit: 'updateClientId' })
	setClientId(clientId: string): string {
		if (clientId !== this.chosenClientId) {
			this.context.commit('setSites', []);
			this.context.commit('setSiteId', '');
			this.context.commit('updateClientId', clientId);
			this.context.dispatch('getSites');
		}
		return clientId;
	}

	@Action({ commit: 'setSites', rawError: true })
	async getSites(): Promise<Array<Site>> {
		let sites: Array<Site & {domainName?: string | null}> = await (await this.siteService.findAll(this.chosenClientId)).data.data;
		
		// site must be enabled and not deleted
		sites = sites.filter((site: Site) => {
			return site.enabled && !site.isDeleted;
		});

		sites.forEach((site) => {
			const domain = site.domains.find(domain => {
				if (domain.isDefault && !domain.isDeleted) {
					return domain;
				}
			});
			if (domain) {
				site.domainName = domain.domain.trim().toLowerCase();
			} else {
				site.domainName = null;
			}
		});

		sites.sort((a: Site & { domainName?: string | null }, b: Site & { domainName?: string | null }): number => {
			if (a.isDefaultSite) {
				return -1;
			}
			if (b.isDefaultSite) {
				return 1;
			}
	
			if (a.domainName && b.domainName) {
				return a.domainName > b.domainName ? 1 : -1;
			}
	
			if (a.domainName) {
				return -1;
			}
			if (b.domainName) {
				return 1;
			}
	
			return 0;
		});

		sites = sites.map((site) => {
			site.name = site.id.trim() + ' - ' + (site.domainName ? site.domainName : '[not set]');
			delete site.domainName;

			return site as Site;
		});

		return sites as Array<Site>;
	}

	
	@Action({ commit: 'setSiteId' })
	setSiteIdAction(siteId: string): string {
		return siteId;
	}

}