




















































































































import { Component, Prop, VModel } from 'vue-property-decorator';
import Vue from 'vue';
import HIRT from '@/components/hirt';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';
import { PageProfile, Block, slotBlockType, SlotMap, Structure } from '@/services/api';
import { KText, KSelect, KRadioGroup } from '@kasasa/fbase-components';

import { namespace } from 'vuex-class';
const blockStore = namespace('blocks');
const structureStore = namespace('structures');

const YesNoFactory = () => {
	return [
		{ text: 'Yes', value: true },
		{ text: 'No', value: false },
	];
};

interface regionMapType {
    [key: string]: string | null;
}

interface dropDownOption {
	text: string,
	value: string | null | number,
}

interface region {
	name: string,
	options: dropDownOption[],
}

const defaultOption: dropDownOption[] = [{
	text: '<Default>',
	value: null,
}];

const notSetOption: dropDownOption[] = [{
	text: '[Not Set]',
	value: null,
}];


@Component({
	components: {
		KText, 
		KSelect,
		KRadioGroup
	},
})
export default class PageProfileCard extends Vue implements HIRT {
	@VModel() localPageProfile!: PageProfile;
	@Prop({ default: false }) readonly ro!: boolean;

	@blockStore.State blocks!: Block[];
	@blockStore.State layoutBlocks!: Block[];
	@blockStore.State contentBlocks!: Block[];
	@blockStore.State slotBlocks!: slotBlockType;
	
	@structureStore.State structures!: Structure[];
	
	readonly yesNoItems = YesNoFactory();

	isMounted = false;
	mounted(): void {
		this.isMounted = true;
	}

	validations(): RuleDecl {
		return {
			localPageProfile: {
				name: 					{ required },
				layoutId: 				{ required },
				contentId: 				{ required},
				enabled: 				{ required },
				hasCta: 					{ required },
				hasForm: 				{ required },
				hasProduct: 			{ required },
				hasStructure: 			{ required },
				structureId: 			{},
				structureTemplateId:	{  required: requiredIf(function (pageProfile: PageProfile): boolean {					
					return pageProfile.structureId? true: false;
				})},
				structureContentId:	{},
			}
		};
	}

	hasErrors(): boolean {
		return this.$v?.$anyError;
	}

	isDirty(): boolean {
		return this.$v?.$anyDirty;
	}

	reset(): void {
		this.$v?.$reset();
	}

	touch(): void{
		this.$v?.$touch();
	}

	get nameErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.localPageProfile?.name?.$invalid && this.$v?.localPageProfile?.name?.$dirty) ? 'Name is required' : null
		) : null;
	}

	get contentRegionErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.localPageProfile?.contentId?.$invalid && this.$v?.localPageProfile?.contentId?.$dirty) ? 'Content Region is required' : null
		) : null;
	}

	get structureTemplateErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.localPageProfile?.structureTemplateId?.$invalid && this.$v?.localPageProfile?.structureTemplateId?.$dirty) ? 'Structure Template is required' : null
		) : null;
	}

	get hasFormErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.localPageProfile?.hasForm?.$invalid && this.$v?.localPageProfile?.hasForm?.$dirty) ? 'Include Form is required' : null
		) : null;
	}

	get hasCtaErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.localPageProfile?.hasCta?.$invalid && this.$v?.localPageProfile?.hasCta?.$dirty) ? 'Include CTA Button is required' : null
		) : null;
	}

	get hasProductErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.localPageProfile?.hasProduct?.$invalid && this.$v?.localPageProfile?.hasProduct?.$dirty) ? 'Include Product is required' : null
		) : null;
	}

	get layoutOptions():  dropDownOption[] {
		return this.mapBlockOptions(this.layoutBlocks);		
	}

	get contentRegionOptions(): dropDownOption[]{
		return this.mapBlockOptions(this.contentBlocks);		
	}

	mapBlockOptions(blocks: Block[]): dropDownOption[] {
		const defaultOption: dropDownOption[] = [{
			text: '<Default>',
			value: 0,
		}];
		return defaultOption.concat(
			blocks
				.sort((a, b) => (a.name > b.name) ? 1 : -1)
				.map((block) => {
					return {
						text: block.name + (block.isDefault ? ' (Default)' : ''),
						value: Number(block.id),
					};
				}));
	}

	get regionMap():regionMapType {
		const regionMap: regionMapType = {};
		this.localPageProfile.slotMaps.forEach( (slot) => {
			const block = this.blocks.find( b => Number(b.id) == slot.slotId );
			if(block) {
				regionMap[block.subType.id] = block.id;
			}				
		});
		Object.keys(this.slotBlocks).forEach( (el) => {
			if(!regionMap[el]) {
				regionMap[el] = null;
			}
		});
		return regionMap;
	}

	get regions(): region[] {
		const regions: region[] = [];		
		if (this.slotBlocks) {
			Object.entries(this.slotBlocks)
				.forEach(([name, blocks]) => {
					regions.push({
						name: name,
						options:
							defaultOption.concat (
								blocks.sort((a, b) => (a.name > b.name) ? 1 : -1)
									.map((block) => {
										return {
											text: block.name + (block.isDefault ? ' (Default)' : ''),
											value: block.id,
										};
									})
							)
					});
					
				});						
		}

		return regions;
	}

	updateSlotMapsModel():void {
		//capture the selected values for each region and set the slotMaps
		//verify that: default was not selected and the region is used for the selected layout
		const slotMapsSelected: SlotMap[] = []; 
		Object.entries(this.regionMap).forEach(([key, value]) => {			
			if(this.showRegion(key) && value) {
				slotMapsSelected.push({slotId: Number(value)});				
			}
		});		
		this.localPageProfile.slotMaps = slotMapsSelected;
	}

	showRegion(name: string): boolean {
		if(this.localPageProfile.layoutId == 0) {
			return this.layoutBlocks.find((el) => el.isDefault)?.slots.find((el) => el.subType == name) ? true : false;			
		}
		const layoutBlock = this.layoutBlocks.find( (el) => el.id == this.localPageProfile.layoutId.toString());
		return layoutBlock?.slots.find( (el) => el.subType == name) ? true : false;
	}

	get structureOptions(): dropDownOption[]{
		return notSetOption.concat(this.structures.map((st) => {
			return {
				text: st.name,
				value: Number(st.id),
			};
		}));	
	}

	get selectedStructure(): Structure | undefined {
		return this.structures.find( (el) => Number(el.id) == Number(this.localPageProfile.structureId));
	}

	get templateOptions(): dropDownOption[]{
		if(this.selectedStructure) {
			return notSetOption.concat(this.selectedStructure.templates.map((el) => {
				return {
					text: el.label,
					value: Number(el.id),
				};
			}));
		}
		return [];	
	}

	get structureSelected(): boolean {
		if(this.localPageProfile.structureId) {
			return true;
		}
		return false;
	}

	get contentOptions(): dropDownOption[]{
		if(this.selectedStructure) {
			return notSetOption.concat(this.selectedStructure.contents.map((el) => {
				return {
					text: el.name,
					value: Number(el.id),
				};
			}));
		}
		return [];	
	}

	structureIdChanged(): void {
		//setting has structure base on current structure select.
		this.localPageProfile.hasStructure = false;
		if(this.localPageProfile.structureId) {
			this.localPageProfile.hasStructure = true;
		}
		this.localPageProfile.structureTemplateId = null;
		this.localPageProfile.structureContentId = null;
	}
	
}
