export interface SubType {
	id: string;
	name: string;
	type: string;
}

export enum BlockExpand {
	SLOTS = 'slots',
	TREE  = 'tree',
}

export enum BlockType {
	GLOBAL_CORE = 'globalCore',
	GLOBAL_CUSTOM = 'globalCustom',
	SITE_CONTENT_REGION = 'siteContentRegion',
	SITE_GENEREAL_REGION = 'siteGeneralRegion',
	SITE_LAYOUT = 'siteLayout',
	SITE_POINTER = 'sitePointer',
	SITE_CUSTOM = 'siteCustom',
}

export interface Type {
	id: BlockType;
	name: string;
	scope: string;
}

export interface Slot {
	nodeSlotId: string;
	subType: string;
}

export interface Block {
	id: string;
	type: Type;
	subType: SubType;
	name: string;
	isDefault: boolean;
	tree: Array<Node>;
	slots: Array<Slot>,
	uid?: string;
}
export interface slotBlockType {
    [key: string]: Block[];
}


export const DINAMIC_PAGE_REGION_ID = '25';