var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"px-0":"","py-0":""}},[_c('k-scroll-btn'),_c('v-row',{staticClass:"justify-end"},[_c('v-col',{staticClass:"py-0 text-right",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"fa-search","single-line":"","hide-details":""},model:{value:(_vm.query.search),callback:function ($$v) {_vm.$set(_vm.query, "search", $$v)},expression:"query.search"}})],1)],1),_c('v-row',{attrs:{"row":""}},[_c('v-col',{staticClass:"px-0 py-4"},[(_vm.items.length)?_c('v-data-table',{attrs:{"items":_vm.items,"sort-by":_vm.query.sortBy,"sort-desc":_vm.query.sortDesc,"disable-pagination":"","hide-default-footer":true,"must-sort":true,"search":_vm.query.search,"custom-filter":_vm.customFilter,"headers":_vm.headers},on:{"update:sortBy":function($event){return _vm.$set(_vm.query, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.query, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.query, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.query, "sortDesc", $event)},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.searchHighlight(item.name, _vm.query.search))}})])]}},{key:"item.hasProduct",fn:function(ref){
var item = ref.item;
return [_c('list-table-label',{attrs:{"value":item.hasProduct}})]}},{key:"item.hasForm",fn:function(ref){
var item = ref.item;
return [_c('list-table-label',{attrs:{"value":item.hasForm}})]}},{key:"item.hasCta",fn:function(ref){
var item = ref.item;
return [_c('list-table-label',{attrs:{"value":item.hasCta}})]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.enabled ? 'primary' : '#E0E0E0'}},[_vm._v(" "+_vm._s(item.enabled ? "Active" : "Inactive")+" ")])]}}],null,true)}):_vm._e(),(!_vm.items.length)?_c('div',{attrs:{"align":"center"}},[_c('v-row',[_c('v-col',{staticClass:"text-px-18 text--secondary"},[_vm._v(" No Page Profiles were found. ")])],1),_c('v-row',[_c('v-col',[_c('img',{attrs:{"svg-inline":"","width":"566","src":require("@/assets/no-items.svg")}})])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }