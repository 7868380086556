import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';
import { PageProfile } from '@/services/api';

export default class PageProfileService extends FBaseService {

	findAll(clientId: number, siteId: number): Promise<AxiosResponse<ResponseEnvelopeCollection<PageProfile>>> {
		return this.apiClient.get(`/api/kcms-pageprofile/${clientId}/site/${siteId}/page-profile`);
	}

	find(clientId: number, siteId: number, id: number): Promise<AxiosResponse<ResponseEnvelope<PageProfile>>> {
		return this.apiClient.get(`/api/kcms-pageprofile/${clientId}/site/${siteId}/page-profile/${id}`);
	}

	create(clientId: number, siteId: number, pageProfile: PageProfile): Promise<AxiosResponse<ResponseEnvelope<PageProfile>>> {
		return this.apiClient.post(`/api/kcms-pageprofile/${clientId}/site/${siteId}/page-profile`, pageProfile);
	}

	update(clientId: number, siteId: number, pageProfileId: number, pageProfile: PageProfile): Promise<AxiosResponse<ResponseEnvelope<PageProfile>>> {
		return this.apiClient.put(`/api/kcms-pageprofile/${clientId}/site/${siteId}/page-profile/${pageProfileId}`, pageProfile);
	}

	delete(clientId: number, siteId: number, pageProfileId: number): Promise<AxiosResponse<ResponseEnvelope<PageProfile>>> {
		return this.apiClient.delete(`/api/kcms-pageprofile/${clientId}/site/${siteId}/page-profile/${pageProfileId}`);
	}

}
