import Vue from 'vue';
import Vuex from 'vuex';
import SiteStore from '@/store/modules/SiteStore';
import { Query } from '@/store/modules/Query';
import BlockStore from '@/store/modules/BlockStore';
import StructureStore  from '@/store/modules/StructureStore';

import { AuthStore, NoticeStore, pendoPlugin } from '@kasasa/fbase-components/lib';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		query: new Query(),
	},
	mutations: {
		RESET_QUERY(state) {
			state.query = new Query();
		}
	},
	actions: {
		resetQuery(context) {
			context.commit('RESET_QUERY');
		},
	},
	modules: {
		auth: AuthStore,
		notices: NoticeStore,
		sites: SiteStore,
		blocks: BlockStore,
		structures: StructureStore,
	},
	plugins: [pendoPlugin]
});

export const enum GroupPerms {
	MAIN_PERM = 'pageProfiles'
}
