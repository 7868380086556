import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import { Structure, StructureExpand } from '@/services/api';
import StructureService from '@/services/StructureService';
import { ExpandableFilter } from '@kasasa/fbase-components/lib';
import { loadingStatus } from '@/utils/loadingStatus';
import { SiteContext} from '@/utils/SiteContext';

@Module({
	namespaced: true
})
export default class StructureStore extends VuexModule {

	structures: Structure[] = [];

	siteContext: SiteContext = {siteId: 0, clientId: 0};
	structuresLoadingStatus: loadingStatus = loadingStatus.UNLOADED;

	structureService = new StructureService;



	@Mutation
	setStructures(structures: Structure[]): void {
		this.structures = structures;
	}

	@Mutation
	updateSiteContext(siteContext: SiteContext): void {
		this.siteContext = siteContext;
	}

	
	@Action
	clearClientId(): void {
		this.context.commit('setStructures', []);
		this.context.commit('updateSiteId', '');
		this.context.commit('updateSiteContext', { siteId: '', clientId: '' });
	}


	@Mutation
	setStructuresLoadingStatus(payload: loadingStatus): void {
		this.structuresLoadingStatus = payload;
	}

	@Action({ commit: 'setStructures', rawError: true })
	async fetchStructures(siteContext: SiteContext): Promise<Structure[]> {


		if (siteContext.clientId == this.siteContext.clientId
			&& siteContext.siteId == this.siteContext.siteId) {
			return this.structures;
		}

		if (this.structuresLoadingStatus === loadingStatus.LOADING) {
			return this.structures;
		}

		this.context.commit('updateSiteContext', siteContext);

		this.context.commit('setStructuresLoadingStatus', loadingStatus.LOADING);
		return await this.structureService.getSiteStructures(
			siteContext.clientId,
			siteContext.siteId,
			(new ExpandableFilter).expand(StructureExpand.CONTENTS).expand(StructureExpand.TEMPLATES)
		).then(resp => {
			this.context.commit('setStructuresLoadingStatus',  loadingStatus.LOADED);
			return resp.data.data || [];
		}).catch(() => {
			this.context.commit('setStructuresLoadingStatus', loadingStatus.UNLOADED);
			return [];
		});	
	}

	
}