export enum StructureExpand {
	CONTENTS = 'contents',
	TEMPLATES  = 'templates',
}

export interface Template {
	id: number,
	label: string,
}

export interface Content {
	id: number,
	name: string,
}
export interface Structure {
	id: string;
	name: string;
	templates: Template[],
	contents: Content[],
}
