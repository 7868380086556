




















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { PageProfile } from '@/services/api';
import { DataTableHeader } from 'vuetify';
import { KScrollBtn } from '@kasasa/fbase-components';
import { searchHighlight } from '@kasasa/fbase-components/lib';
import { RouteName } from '@/router';
import { Query } from '@/store/modules/Query';
import { Action, State } from 'vuex-class';
import ListTableLabel from '@/components/ListTableLabel.vue';

const reverseSort = (a: number, b: number): number => (a > b ? -1 : 1);

@Component({
	components: {
		KScrollBtn,
		ListTableLabel
	},
})
export default class PageProfileListTable extends Vue {
	@Prop() items!: PageProfile[];
	@Prop() readonly clientId !: number;
	@Prop() readonly siteId !: number;
	@Prop({ default: false }) readonly ro !: boolean;


	@Action('resetQuery') resetQuery!: () => void;
	@State('query') query!: Query;

	get headers(): DataTableHeader[] {
		const dataheaders: DataTableHeader[] = [
			{ text: 'Name', value: 'name'},
			{ text: 'Product', value: 'hasProduct' },
			{ text: 'Form', value: 'hasForm'},
			{ text: 'CTA Button', value: 'hasCta'},
			{ text: 'Status', 			   value: 'enabled', width: '100px', sort: reverseSort }, // reverse sort so 1 (Active) is ascending
		];

		return dataheaders;
	}
	
	created(): void {
		if (this.query.siteId != this.$route.params.siteId) this.resetQuery();		
		this.query.siteId = this.$route.params.siteId;
	}

	handleClick(row: PageProfile): void {
		if (!row.id) {
			return;
		}

		const routeName = RouteName.PAGE_PROFILE_EDIT;
		this.$router.push({name: routeName, params: { clientId: this.clientId.toString(), siteId: this.siteId.toString(), pageProfileId: row.id.toString() }});
	}

	searchHighlight(value: string | number, querySearch: string): string | number {
		return searchHighlight(value, querySearch);
	}

	customFilter(index: number, search: string, item: PageProfile): boolean | string {
		const lSearch = search.toLowerCase();
		return (item.name && item.name.toLowerCase().indexOf(lSearch) !== -1);
	}

}
