









import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	components: {
	},
})
export default class ListTableLabel extends Vue {
	@Prop() value!: string|number|boolean;

	get printValue(): string | number {
		if (typeof this.value == 'boolean') {
			return this.value? 'Yes': '';
		}
		return this.value;
	}
	
}
