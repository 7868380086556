import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelopeCollection, ExpandableFilter } from '@kasasa/fbase-components/lib'; 
import { Structure } from '@/services/api';

export default class StructureService extends FBaseService {


	getSiteStructures(clientId: number, siteId: number, filter: ExpandableFilter): Promise<AxiosResponse<ResponseEnvelopeCollection<Structure>>> {
		return this.apiClient.get(`/api/kcms-structure/${clientId}/site/${siteId}/structure`, {
			params: filter.getQuery()
		});
	}

}