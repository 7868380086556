import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';
import { Block, BlockType, BlockExpand, slotBlockType, DINAMIC_PAGE_REGION_ID } from '@/services/api';
import BlockService from '@/services/BlockService';
import { ExpandableFilter } from '@kasasa/fbase-components/lib';
import { loadingStatus } from '@/utils/loadingStatus';
import { SiteContext} from '@/utils/SiteContext';
import { groupBy } from 'lodash';


@Module({
	namespaced: true
})
export default class BlockStore extends VuexModule {

	blocks: Block[] = [];
	layoutBlocks: Block[] = [];
	contentBlocks: Block[] = [];
	slotBlocks: slotBlockType = {};

	siteContext: SiteContext = { siteId: 0, clientId: 0 };
	blocksLoadingStatus: loadingStatus = loadingStatus.UNLOADED;

	blockService = new BlockService;

	@Mutation
	setLayoutBlocks(blocks: Block[]): void {
		this.layoutBlocks = blocks.filter((el) => el.type.id === BlockType.SITE_LAYOUT);				
	}

	@Mutation
	setContentBlocks(blocks: Block[]): void {
		this.contentBlocks = blocks.filter((el) => ((el.type.id === BlockType.SITE_CONTENT_REGION) && (el.subType.id === DINAMIC_PAGE_REGION_ID)));
	}

	@Mutation
	setSlotBlocks(blocks: Block[]): void {
		this.slotBlocks= groupBy(blocks.filter((el) => el.type.id === BlockType.SITE_GENEREAL_REGION), 'subType.id');
	}


	@Mutation
	setBlocks(blocks: Block[]): void {
		this.blocks = blocks;		
	}

	@Mutation
	updateSiteContext(siteContext: SiteContext): void {
		this.siteContext = siteContext;
	}
	
	@Action
	clearClientId(): void {
		this.context.commit('setBlocks', []);
		this.context.commit('setLayoutBlocks', []);		
		this.context.commit('setContentBlocks', []);
		this.context.commit('setSlotBlocks', []);
		this.context.commit('updateSiteContext', { siteId: 0, clientId: 0});
	}


	@Mutation
	setBlocksLoadingStatus(payload: loadingStatus): void {
		this.blocksLoadingStatus = payload;
	}

	@Action({ commit: 'setBlocks', rawError: true })
	async fetchBlocks(siteContext: SiteContext): Promise<Block[]> {

		if (siteContext.clientId == this.siteContext.clientId
			&& siteContext.siteId == this.siteContext.siteId) {
			return this.blocks;
		}

		if (this.blocksLoadingStatus === loadingStatus.LOADING) {
			return this.blocks;
		}
		this.context.commit('updateSiteContext', siteContext);

		this.context.commit('setBlocksLoadingStatus', loadingStatus.LOADING);
		return await this.blockService.getSiteBlocks(
			siteContext.clientId,
			siteContext.siteId,
			(new ExpandableFilter).expand(BlockExpand.SLOTS)
		).then(resp => {
			this.context.commit('setBlocksLoadingStatus', loadingStatus.LOADED);
			this.context.commit('setLayoutBlocks', resp.data.data);
			this.context.commit('setContentBlocks', resp.data.data);
			this.context.commit('setSlotBlocks', resp.data.data);
			return resp.data.data;
		}
		).catch(() => {
			this.context.commit('setBlocksLoadingStatus', loadingStatus.UNLOADED);
			this.context.commit('setLayoutBlocks', []);		
			this.context.commit('setContentBlocks', []);
			this.context.commit('setSlotBlocks', []);
			return [];
		});
	}
}