import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelopeCollection, ExpandableFilter} from '@kasasa/fbase-components/lib'; 
import { Block } from '@/services/api';

export default class BlockService extends FBaseService {


	getSiteBlocks(clientId: number, siteId: number, filter: ExpandableFilter): Promise<AxiosResponse<ResponseEnvelopeCollection<Block>>> {
		return this.apiClient.get(`/api/kcms-block/${clientId}/site/${siteId}/block`, {
			params: filter.getQuery()
		});
	}

}