










































































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { KCardHeader, KCardHeaderBtn, KSpinner, KCardFooter, KCardFooterBtn } from '@kasasa/fbase-components';
import { AuthGroupManager, Dialog, Alert, NoticeClass, NoticeResponse, KCrumb } from '@kasasa/fbase-components/lib';
import { namespace } from 'vuex-class';

import PageProfileService from '@/services/PageProfileService';
import { SiteContext} from '@/utils/SiteContext';
import { GroupPerms } from '@/store';
import PageProfileCard from '@/components/form/PageProfileCard.vue';
import { PageProfile, Block, Structure, PageProfileFactory } from '@/services/api';
import { RouteName } from '@/router';
import { Route, RawLocation, NavigationGuardNext } from 'vue-router';
import HIRT from '@/components/hirt';


const auth = namespace('auth');
const blocks = namespace('blocks');
const structures = namespace('structures');

@Component({
	components: {
		KSpinner,
		KCardHeader,
		KCardHeaderBtn,
		KCardFooter,
		KCardFooterBtn,
		PageProfileCard

	}
})
export default class PageProfileFormPage extends Vue {	
	@Prop() siteId !: number;
	@Prop() clientId !: number;
	@Prop() pageProfileId !: number;
	@Prop({ default: 'edit'} ) readonly mode !: string;

	@auth.State authManager!: AuthGroupManager;
	@blocks.Action  fetchBlocks!: (siteContext: SiteContext) => Promise<Block[]>;	
	@structures.Action  fetchStructures!: (siteContext: SiteContext) => Promise<Structure[]>;
	
	
	isLoaded = false;

	pageProfileSvc = new PageProfileService(this.$store);

	pageProfile = {} as PageProfile;

	loadData = '';

	get ro() : boolean{
		return !this.authManager.canWrite(GroupPerms.MAIN_PERM);
	}

	get showActions(): boolean {
		return this.$route.meta?.mode === 'edit';
	}

	@Watch('$route', {immediate: true, deep: true})
	load(): void {

		this.fetchBlocks({siteId:this.siteId, clientId:this.clientId});
		this.fetchStructures({siteId:this.siteId, clientId:this.clientId});

		// load the Page Profile object
		if (this.mode === 'edit') {
			this.loadPageProfile();
		
		} else {
			this.pageProfile = PageProfileFactory(this.siteId);
			this.isLoaded = true;
			// store initial state
			this.setLoadedData(this.pageProfile);
		}

	}

	setLoadedData(data: PageProfile): void {
		this.loadData = JSON.stringify(data);
	}

	loadPageProfile(): void {
		this.pageProfileSvc.find(this.clientId, this.siteId, this.pageProfileId)
			.then(resp => {
				this.pageProfile = resp.data.data;
				this.isLoaded = true;
				this.setLoadedData(this.pageProfile);
			});
	}

	get isDirty(): boolean {
		return this.loadData !== JSON.stringify(this.pageProfile);
	}

	get title(): string {
		return this.$route.meta?.mode == 'add' ? 'Add New Page Profile' : 'Edit';
	}

	get crumbs(): KCrumb[] {
		const crumbs = [] as KCrumb[];
		crumbs.push(
			{
				key: '0',
				text: 'Page Profiles',
				disabled: false,
				link: true,
				exact: true,
				to: { name: RouteName.PAGE_PROFILE_LIST, params: { clientId: this.clientId.toString(), siteId: this.siteId.toString() } }
			}
		);

		if (this.$route.meta?.mode == 'add') {
			crumbs.push({
				key:'1',
				text: 'Add New Page Profile',
				disabled: true
			});
		} else {
			crumbs.push({
				key: '2',
				text: this.title,
				disabled: true
			});
		}
		return crumbs;
	}

	cancelAction(): void {
		// beforeRouteLeave() will detect unsaved changes
		this.goToList();
	}

	goToList(): void {
		const route = {params: this.$route.params, name: RouteName.PAGE_PROFILE_LIST} as RawLocation;
		this.$router.push(route);
	}

	async deleteAction(): Promise<void> {
		const dialog = new Dialog(
			'Delete this Page Profile?',
			`Are you sure you want to delete "${this.pageProfile?.name}"? Caution: this can't be undone.`,
			'DELETE'
		);

		dialog.setDeclineLabel('CANCEL')
			.setDismissable(false);
		const success = new Alert(`${this.pageProfile?.name} has been deleted.`, NoticeClass.SUCCESS);

		success.setTimeout(6000);

		const res = await this.$store.dispatch('notices/add', dialog);
		switch (res) {
			case NoticeResponse.ACCEPT:
				this.pageProfileSvc.delete(this.clientId, this.siteId, this.pageProfileId)
					.then(() => {
						this.$store.dispatch('notices/add', success);
						this.reset();
						this.goToList();
					})
					.catch(() => {
						this.$store.dispatch('notices/add', new Alert(`Unable to delete "${this.pageProfile.name}"`,
							NoticeClass.ERROR
						)
						);
					});
				break;
			case NoticeResponse.DECLINE:
			default:
				// do nothing
				// the modal closes
				break;
		}
	}

	generateSaveActionPromise(): Promise<void> {
		const saved = new Alert(`${this.pageProfile.name} is successfully saved.`, NoticeClass.SUCCESS);
		saved.setTimeout(6000);
		return new Promise((resolve, reject) =>{
			if (this.$route.meta?.mode === 'add') {
				this.pageProfileSvc.create(this.clientId, this.siteId, this.pageProfile)
					.then((resp) => {
						this.$store.dispatch('notices/add', saved);

						// just in case
						this.pageProfile = resp.data.data;

						// reset all the vuelidates and loadedData;
						this.reset();

						// will send to new FormPage, however saveAndClose will override and send to navlist
						const params = {...this.$route.params, pageProfileId: this.pageProfile.id || ''};
						const route = {params: params, name: RouteName.PAGE_PROFILE_EDIT} as RawLocation;
						this.$router.push(route);

						resolve();
					})
					.catch(() => {									
						const error = new Alert(`${this.pageProfile.name} failed to save.`, NoticeClass.ERROR);
						error.setTimeout(6000);
						this.$store.dispatch('notices/add', error);
						reject();
					});
			} else {
				this.pageProfileSvc.update(this.clientId, this.siteId, this.pageProfileId, this.pageProfile)
					.then((resp) => {
						this.pageProfile = resp.data.data;
						this.$store.dispatch('notices/add', saved);

						// reset all the vuelidates;
						this.reset();												
						resolve();
					})
					.catch((e) => {
						// no need to listen for 401, 403 and 500
						if (e.response.status === 404) {
							// throw an Alert notice?
							const notFound = new Alert(`Page Profile "${this.$route.params.id}" is not found. Go back and try again.`, NoticeClass.ERROR);
							this.$store.dispatch('notices/add', notFound);
						}
						reject();
					});
			}
		});
	}
	saveAction(): Promise<void> | void {
		if (this.checkForErrors()) {
			return;
		}

		return this.generateSaveActionPromise();
	}

	checkForErrors(): boolean {
		let errors = false;

		const header = this.$refs.pageProfileCard as PageProfileCard & HIRT;

		header.touch();

		errors = header.hasErrors();

		if (errors) {
			const broken = new Alert('Unable to save. Please make sure all required fields are completed without errors.', NoticeClass.ERROR);
			this.$store.dispatch('notices/add', broken);
		}

		return errors;
	}

	checkForDirty(): boolean {
		return (this.$refs.pageProfileCard as PageProfileCard & HIRT).isDirty() || this.isDirty;
	}

	async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
		if (this.checkForDirty()) {
			const dialog = new Dialog('Unsaved Changes', 'You have unsaved changes on this page that will be lost if you leave now. Are you sure?', 'LEAVE WITHOUT SAVING');
			dialog.setDeclineLabel('STAY ON THIS PAGE')
				.setDismissable(false);

			const res = await this.$store.dispatch('notices/add', dialog);
			switch (res) {
				case NoticeResponse.ACCEPT:
					this.reset();
					next();
					break;
				case NoticeResponse.DECLINE:
				default:
					// staying on the page
					break;
			}
		} else {
			this.reset();
			next();
		}
	}

	reset(): void {
		this.setLoadedData(this.pageProfile);
		(this.$refs.pageProfileCard as PageProfileCard & HIRT).reset();
	}

	saveCloseAction(): Promise<void> | void {
		if (this.checkForErrors()) {
			return;
		}

		return this.generateSaveActionPromise().then(() => {
			this.goToList();
		});
	}
	

}

